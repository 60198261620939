<script>
import api from "@/services/api-service";
import { findAll } from "@/services/users-management-service";
import { getPlans } from "@/services/paypal-service";

export default {
  components: {
    "form-subscriptions": () => import("@/views/Subscriptions/FormSubscriptions"),
  },
  created() {
    this.getSubscriptions();
    this.getPlans();
    this.getUsers();
  },
  data: () => ({
    dialogForm: false,
    cancellationReasonDialog: false,
    headers: [
      { text: "Usuário", value: "user.name" },
      { text: "Plano", value: "paypalPlan.name" },
      { text: "Status", value: "statusLabel" },
      { text: "Data de assinatura", value: "createdAt" },
      { text: "Próxima cobrança", value: "nextBillingTime" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    subscriptions: [],
    selectedSubscription: undefined,

    // **Filter-related Data Properties**
    search: '',
    filterStatus: null,
    filterPlan: null,
    filterUser: null,

    // For populating filter dropdowns
    plans: [],
    users: [],
    statuses: [
      { key: "APPROVAL_PENDING", label: "Pendente de aprovação" },
      { key: "CREATED", label: "Criada" },
      { key: "ACTIVE", label: "Ativa" },
      { key: "SUSPENDED", label: "Suspensa" },
      { key: "CANCELED", label: "Cancelada" },
      { key: "EXPIRED", label: "Expirada" },
    ],
  }),
  computed: {
    filteredSubscriptions() {
      return this.subscriptions.filter((subscription) => {
        const matchesStatus = this.filterStatus
          ? subscription.status === this.filterStatus
          : true;

        const matchesPlan = this.filterPlan
          ? subscription.paypalPlanId === this.filterPlan
          : true;

        const matchesUser = this.filterUser
          ? subscription.userId === this.filterUser
          : true;

        const matchesSearch = this.search
          ? Object.values(subscription).some((value) =>
              String(value).toLowerCase().includes(this.search.toLowerCase())
            )
          : true;

        return matchesStatus && matchesPlan && matchesUser && matchesSearch;
      });
    },
  },
  methods: {
    createdForm(subscription) {
      this.dialogForm = false;
      this.getSubscriptions();
    },
    async deleteSubscription(subscription) {
      try {
        this.$store.dispatch("loading/openDialog");

        await api().delete(`/paypal/subscriptions/${subscription.subscriptionId}`);

        this.subscriptions = this.subscriptions.filter((s) => s.subscriptionId !== subscription.subscriptionId);

        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    updatedForm(subscriptionEdited) {
      this.dialogForm = false;
      this.getSubscriptions();
    },
    openModal(subscription) {
      this.selectedSubscription = subscription;
      this.dialogForm = true;
    },

    openModalCancellation(subscription) {
      this.selectedSubscription = subscription;
      this.cancellationReasonDialog = true;
    },

    closeModalCancellation() {
      this.cancellationReasonDialog = false;
    },
    closeModal() {
      this.dialogForm = false;
      this.selectedSubscription = undefined;
    },
    async getSubscriptions() {
      try {
        this.$store.dispatch("loading/openDialog");

        const response = await api().get('/paypal/subscriptions');

        const status = {
          APPROVAL_PENDING: 'Pendente de aprovação',
          CREATED: 'Criada',
          ACTIVE: 'Ativa',
          SUSPENDED: 'Suspensa',
          CANCELED: 'Cancelada',
          EXPIRED: 'Expirada',
        };

        response.data = response.data.map((subscription) => {
          subscription.nextBillingTime = new Date(subscription.nextBillingTime).toLocaleDateString();
          subscription.createdAt = new Date(subscription.createdAt).toLocaleDateString();
          subscription.statusLabel = status[subscription.status];
          subscription.canEdit = !subscription.subscriptionId.startsWith('I-') && !subscription.subscriptionId.startsWith('sub_');
          return subscription;
        });

        this.subscriptions = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    // **Filter-related Methods**
    clearFilters() {
      this.search = '';
      this.filterStatus = null;
      this.filterPlan = null;
      this.filterUser = null;
    },

    async getPlans() {
      try {
        const { data } = await getPlans();
        this.plans = data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },

    async getUsers() {
      try {
        const { data } = await findAll();
        this.users = data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
};
</script>
<template>
  <v-container>
    <perseu-card title="Assinaturas">
      <template #title-right>
        <v-btn @click="openModal()" color="secondary"> Nova Assinatura </v-btn>
      </template>

     

      <v-flex slot="content">
         <!-- **Filter Section** -->
      <v-card class="mb-4" flat>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="search"
              label="Buscar"
              prepend-icon="mdi-magnify"
              clearable
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="filterStatus"
              :items="statuses"
              item-text="label"
              item-value="key"
              label="Filtrar por Status"
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="filterPlan"
              :items="plans"
              item-text="name"
              item-value="idPaypal"
              label="Filtrar por Plano"
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="filterUser"
              :items="users"
              item-text="name"
              item-value="id"
              label="Filtrar por Usuário"
              clearable
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="primary" @click="clearFilters">Limpar Filtros</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <!-- **End of Filter Section** -->
        <v-data-table
          :items="filteredSubscriptions"
          :headers="headers"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <table-action-button
              @click="openModalCancellation(item)"
              text="Motivo de cancelamento"
              icon="chat"
              :disabled="item.status !== 'CANCELED'"
            />
            <table-action-button
              @click="openModal(item)"
              text="Editar"
              icon="edit"
              :disabled="!item.canEdit"
            />

            <table-action-button
              @click="deleteSubscription(item)"
              text="Deletar"
              icon="delete"
              :disabled="!item.canEdit"
            />
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>

    <v-dialog v-if="dialogForm" v-model="dialogForm" max-width="600">
      <form-subscriptions
        :selectedSubscription="selectedSubscription"
        @created="createdForm"
        @updated="updatedForm"
        @closed="closeModal"
      />
    </v-dialog>

    <v-dialog v-if="cancellationReasonDialog" v-model="cancellationReasonDialog" max-width="600" style="background-color: white;">
      <v-card>
        <v-card-title> Motivo do cancelamento </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="mt-2">
          <p> {{ selectedSubscription.cancellationReason }} </p>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end;">
          <v-btn @click="closeModalCancellation" color="secondary"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
